import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home'),
    children: [
      {
        path: '/started',
        name: 'started',
        component: () => import('@/views/Started')
      }, {
        path: '/demo',
        name: 'demo',
        component: () => import('@/views/Demo')
      }, {
        path: '/codes',
        name: 'codes',
        component: () => import('@/views/Codes')
      }, {
        path: '/upgrade',
        name: 'upgrade',
        component: () => import('@/views/Plan')
      }, {
        path: '/ons',
        name: 'ons',
        component: () => import('@/views/Ons')
      }, {
        path: '/order',
        name: 'order',
        component: () => import('@/views/Order')
      }, {
        path: '/support',
        name: 'support',
        component: () => import('@/views/Support')
      }, {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile')
      }, {
        path: '/invite',
        name: 'invite',
        component: () => import('@/views/InviteDetail')
      },{
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/Setting')
      }
    ]
  },{
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login')
  },{
    path: '/admin/test/hillson/19910712',
    name: 'admin',
    component: () => import('@/views/Admin')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
