<template>
  <svg t="1710313921966" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5909" :width="size" :height="size" :fill="color">
    <path d="M707.648 947.2h-51.2v-206.528c0-28.224 22.976-51.2 51.2-51.2H876.8v51.2h-169.152V947.2z" p-id="5910"></path>
    <path d="M682.048 972.8H172.8a51.264 51.264 0 0 1-51.2-51.2V102.4c0-28.224 22.976-51.2 51.2-51.2h407.68a25.6 25.6 0 0 1 22.08 12.672l71.232 121.344 177.28-0.896c28.352 0 51.328 22.976 51.328 51.2v479.552a25.408 25.408 0 0 1-6.016 16.448l-194.752 232.128a25.728 25.728 0 0 1-19.584 9.152zM172.8 102.4v819.2h497.28l181.12-215.872V235.52l-191.872 0.96a24.512 24.512 0 0 1-22.208-12.672L565.824 102.336H172.736z" p-id="5911"></path>
    <path d="M859.2 133.504h-153.6a25.6 25.6 0 1 1 0-51.2h153.6a25.6 25.6 0 1 1 0 51.2zM521.6 435.2H268.8a25.6 25.6 0 1 1 0-51.2h252.8a25.6 25.6 0 1 1 0 51.2zM416 307.2H268.8a25.6 25.6 0 1 1 0-51.2h147.2a25.6 25.6 0 1 1 0 51.2zM742.4 563.2H268.8a25.6 25.6 0 1 1 0-51.2h473.6a25.6 25.6 0 1 1 0 51.2zM332.8 691.2h-64a25.6 25.6 0 1 1 0-51.2h64a25.6 25.6 0 1 1 0 51.2z" p-id="5912"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-file",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>