export default {
  title: "Snowblue AI",
  login: {
    t1: "Email",
    t2: "Enter your email",
    t3: "Password",
    t4: "Enter your password",
    t5: "Sign In With Google",
    t6: "Sign In",
    t7: "Don't have an account?",
    t8: "Sign Up",
    t9: "Create an account",
    t10: "I agree to SnowBlueAI's Terms of Service and Privacy Policy.",
    t11: "Already have an account?",
    t13: "Signing in...",
    t14: "Signing up...",
    t15: "Loading...",
    t16: "Deleting...",
  },
  home: {
    t1: "Get Started",
    t2: "Demo & Codes",
    t3: "Demo",
    t4: "Codes",
    t5: "Plan Upgrade",
    t6: "Upgrade",
    t7: "Add-ons",
    t8: "Order Record",
    t9: "Support Center",
    t10: "Credits available",
    t11: "Profile",
    t12: "Logout"
  },
  header: {
    cs: "Chatbot Settings",
    demo: "You can test your created AI Assistant here.",
    ons: "When the package you choose is not enough, you can buy more services.",
    id: "Details of Invited Friends",
  },
  started: {
    t1: "AI Assistant Configuration Submission",
    t2: "Set Assistant Name",
    t3: "Pick a name that defines your assistant.",
    t4: "Choose Assistant Model",
    t5: "Change a model that defines your assistant.",
    t6: "Set Assistant Instructions",
    t7: "Set a instructions that defines your assistant.",
    t8: "Upload Knowledge Files",
    t9: "Build your chatbot's knowledge base by uploading files. These files train your chatbot to answer questions accurately.",
    t10: "Choose Files",
    t12: "A maximum of 10 files, each file not exceeding 10 MB.",
    t13: "Confirmation Information",
    t14: "Assistant Name :",
    t15: "Model :",
    t16: "Instructions :",
    t17: "Congratulations, you have successfully created a AI assistant. Start chatting with it now.",
    b1: "Next",
    b2: "Back",
    b3: "Get status",
    b4: "Choose Plan",
    b5: "Confirm",
    b6: "Visit Plan & Pay",
    b7: "Visit Demo",
  },
  demo: {
    t1: "Send a message.",
    t2: "Chatbot Selection",
    t3: "LLM Selection",
    t4: "Conversation",
    t5: "Question",
  },
  codes: {
    t1: "AI Assistant Chatbot Bubble",
    t2: "To display the chatbot as a convenient popup bubble, insert this code into the \x3Chead\x3E section of your website:",
    t3: "AI Assistant Chatbot Iframe (Embedded)",
    t4: "To display the chatbot as a convenient popup bubble, insert this code into the \x3Chead\x3E section of your website:",
  },
  settings: {
    t1: "Delete\xA0Chatbot",
    t2: "Add\xA0content",
    t3: "File\xA0Name",
    t4: "Upload\xA0Date",
  },
  plan: {
    t1: "Please select your method of payment",
    t2: "Ali Pay",
    t3: "Wechat Pay",
    t4: "Credit Cards",
    p0: {
      a: "Free Plan",
      b: "",
      c: "6,000 words",
      d: "Free",
      e: "10 messages",
      f: "10 messages",
      g: "Basic",
      h: "Email",
      i: "Website and WhatsApp",
      j: "1 AI Assistants",
    },
    p1: {
      a: "GPT-3.5 Turbo",
      b: "( Monthly )",
      c: "200,000 words",
      d: "$99",
      e: "500 messages / Daily",
      f: "15,000 messages / Monthly",
      g: "Basic",
      h: "Email",
      i: "Website and WhatsApp",
      j: "5 AI Assistants",
    },
    p2: {
      a: "GPT-3.5 Turbo",
      b: "( Yearly )",
      c: "2.4 million words / Years",
      d: "$950",
      e: "500 messages / Daily",
      f: "15,000 messages / Monthly",
      g: "Basic",
      h: "Priority Email",
      i: "Website and WhatsApp",
      j: "5 AI Assistants",
      k: "$1188",
    },
    p3: {
      a: "GPT-4o",
      b: "( Monthly )",
      c: "100,000 words",
      d: "$199",
      e: "500 messages / Daily",
      f: "15,000 messages / Monthly",
      g: "Advanced",
      h: "Email and Chat",
      i: "Website and WhatsApp",
      j: "5 AI Assistants",
    },
    p4: {
      a: "GPT-4o",
      b: "( Yearly )",
      c: "1.2 million words / Years",
      d: "$1910",
      e: "500 messages / Daily",
      f: "15,000 messages / Monthly",
      g: "Advanced",
      h: "Priority Email and Chat",
      i: "Website and WhatsApp",
      j: "5 AI Assistants",
      k: "$2388",
    },
    o: "(20% discount)",
    alert1 : "After the payment is successful, please wait for backend processing, which is usually completed within 3 minutes. You can check the order progress in the Order Record. If it is not successfully updated after 1 hour, please contact the administrator.",
    alert2 : "If the browser does not open the checkout page, please copy the following link to manually open the checkout.",
  },
  ons: {
    t1: "Extra Message Credits",
    t2: "Extra Chatbots",
    t3: "Chat Records",
    c1: "$10 per 1000 message",
    c2: "credits/month",
    c3: "$5 per 1 chatbot/month.",
    c4: "Retrieve the complete chat history from your AI Assistant chatbots.",
    w: "I want",
    e1: "extra 1000 message credits every month.",
    e2: "extra chatbots every month.",
    b1: "You will be charged",
    b2: "$10/Month",
    b3: "$5/Month",
    b4: "$49/Month",
    u1: "Coming soon",
  },
  order: {
    th1: "Name",
    th2: "Status",
    th3: "Method",
    th4: "Start-date",
    th5: "Close-date",
    th6: "Order-id",
    th7: "Refresh",
    status: {
      s1: "Pending",
      s2: "Paid",
      S3: "Closed"
    },
    b1: "Unsubscribe",
    b2: "Pay",
  },
  support: {
    qa1: {
      q: "What enhancements does the GPT-4o package offer over the GPT-3.5 package?",
      a: "The GPT-4o package introduces advanced functionalities, enabling more intricate interactions and a broader scope of integration capabilities, showcasing the latest developments in artificial intelligence technology.",
    },
    qa2: {
      q: "Is it possible to modify my subscription plan after signing up?",
      a: "Yes, subscribers have the flexibility to change their subscription level at any moment to align with their evolving requirements.",
    },
    qa3: {
      q: "What are the implications of exceeding my allocated word limit?",
      a: "Should your usage surpass the designated word limit, additional words will incur charges at a predefined rate, with prior notification before any extra fees are applied.",
    },
    qa4: {
      q: "Does Snowblue AI Assistant offer a trial period for new users?",
      a: "New users can take advantage of a 7-day free trial to explore the full capabilities of the AI Assistant service before committing to a paid subscription.",
    },
    qa5: {
      q: "What type of customer support does Snowblue AI Assistant provide?",
      a: "Every subscription plan includes email support, with additional chat support available for GPT-4o package subscribers. Annual subscribers receive priority in their support queries.",
    },
    qa6: {
      q: "How does AI Assistant ensure the security and privacy of my data?",
      a: "AI Assistant prioritizes data security by utilizing encrypted AWS servers for storage, ensuring that your documents remain private and inaccessible to others.",
    },
    qa7: {
      q: "What formats can I use to provide data for my chatbot?",
      a: "The service accommodates a variety of data inputs, including multiple file types, direct text entry to create a versatile chatbot experience.",
    },
    qa8: {
      q: "Can I personalize my AI Assistant chatbot?",
      a: "Users can customize their chatbot with a unique prompt, name, personality traits, and specific instructions to tailor the chatbot's responses.",
    },
    qa9: {
      q: "How can I integrate AI Assistant into my website?",
      a: "Integrating the AI Assistant into your website is straightforward, requiring just the training of your custom AI model and the choice between embedding an iframe or adding a chat bubble.",
    },
    qa10: {
      q: "What languages does AI Assistant support, and can it cater to international audiences?",
      a: "With support for over 100 languages, AI Assistant can respond to queries in various languages, making it a versatile tool for engaging with a global audience.",
    },
    qa11: {
      q: "Are there any restrictions on the number of users who can interact with my chatbot?",
      a: "The chatbot is accessible to anyone who possesses the link, with no user limit imposed.",
    },
    qa12: {
      q: "Is it possible to incorporate multiple files into a single chatbot?",
      a: "Yes, users have the capability to upload numerous files to a single chatbot for a comprehensive data integration.",
    },
    qa13: {
      q: "How many chatbots am I allowed to create with AI Assistant?",
      a: "The number of chatbots you can create is determined by your subscription tier, with each knowledge base equating to one chatbot.",
    },
  },
  profile: {
    t1: "Need more?",
    t2: "Upgrade Now",
    t3: "Invite friends",
    t4: "Both the inviter and the invitee can receive 5 credits.",
    t5: "Detail",
    t6: "change password",
    t7: "Current password",
    t8: "New password",
    t9: "Confirm new password",
    t10: "Confirm change",
    t11: "Enter your current password",
    t12: "Enter your password",
    t13: "Confirm your password",
  },
  invited: {
    t1: "Total",
    t2: "Invitee Email",
    t3: "Registration Time",
    t4: "Credits Earned"
  },
  msg: {
    s1: "Copy invite link success",
    s2: "Switched successfully",
    s3: "Switched error. Please try again or call admin",
    s4: "Copy Success",
    s5: "Login Failure",
    s6: "Sign up success",
    s7: "Please check terms",
    s8: "Email and password cannot be empty",
    s9: "Please purchase a subscription plan first",
    s10: "Create order error",
    s11: "The order has expired, please resubscribe to the plan",
    s12: "Change Success",
    s13: "Error",
    s14: "Change password error, please try again",
    s15: "Change Chat Name Success",
    s16: "Change Instructions Success",
    s17: "No more than 10 files can be selected",
    s18: "Add Success",
    s19: "Add Error",
    s20: "Delete File Error",
    s21: "Delete Chatbot Success",
    s22: "No valid transaction found, it may be in progress",
    s23: "The name of the assistant cannot exceed 30 characters",
    s24: "The name of the assistant cannot be empty",
    s25: "The model cannot be empty",
    s26: "Instructions length must not exceed 32768 characters",
    s27: "The instructions cannot be empty",
    s28: "AI Assistant already created. No more free creations",
    s29: "Please upload at least one file",
    s30: "Failed to create AI assistant, please try creating again",
    s31: "The current subscription does not allow for the creation of a chatbot with the GPT-4o model",
    s32: "The file size must not exceed 10MB",
    s33: "No more than 10 files can be uploaded",
    s34: "Net error"
  }
}
