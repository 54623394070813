<template>
  <svg t="1715938285348" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8189" :width="size" :height="size">
    <path d="M512 1024c-137.309091 0-265.309091-53.527273-361.890909-150.109091S0 649.309091 0 512s53.527273-265.309091 150.109091-361.890909S374.690909 0 512 0s265.309091 53.527273 361.890909 150.109091S1024 374.690909 1024 512s-53.527273 265.309091-150.109091 361.890909S649.309091 1024 512 1024z m0-977.454545C387.490909 46.545455 271.127273 95.418182 182.690909 182.690909 95.418182 271.127273 46.545455 387.490909 46.545455 512s48.872727 240.872727 136.145454 329.309091C271.127273 928.581818 387.490909 977.454545 512 977.454545s240.872727-48.872727 329.309091-136.145454C928.581818 752.872727 977.454545 636.509091 977.454545 512s-48.872727-240.872727-136.145454-329.309091C752.872727 95.418182 636.509091 46.545455 512 46.545455z" :fill="color1" p-id="8190"></path>
    <path d="M512 93.090909c231.563636 0 418.909091 187.345455 418.909091 418.909091S743.563636 930.909091 512 930.909091 93.090909 743.563636 93.090909 512 280.436364 93.090909 512 93.090909z" :fill="color2" p-id="8191"></path>
  </svg>
</template>

<script>
export default {
  name: 'icon-recording',
  props: {
    size: {
      type: String,
      default: '24'
    },
    color1: {
      type: String,
      default: '#333',
    },
    color2: {
      type: String,
      default: '#D00000',
    }
  }
}
</script>

<style scoped>

</style>