<template>
  <svg viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="size" :height="size" :fill="color" id="9000" stroke-width="0.5" :stroke="color">
    <path d="M17.3863636,3.27272727 L13.5,3.27272727 L13.5,1.84090909 C13.5,0.818181818 12.6818182,0 11.6590909,0 L6.34090909,0 C5.31818182,0 4.5,0.818181818 4.5,1.84090909 L4.5,3.27272727 L0.613636364,3.27272727 C0.286363636,3.27272727 0,3.55909091 0,3.88636364 C0,4.21363636 0.286363636,4.5 0.613636364,4.5 L17.3863636,4.5 C17.7136364,4.5 18,4.21363636 18,3.88636364 C18,3.55909091 17.7136364,3.27272727 17.3863636,3.27272727 Z M5.72727273,3.27272727 L5.72727273,1.84090909 C5.72727273,1.51363636 6.01363636,1.22727273 6.34090909,1.22727273 L11.6590909,1.22727273 C11.9863636,1.22727273 12.2727273,1.51363636 12.2727273,1.84090909 L12.2727273,3.27272727 L5.72727273,3.27272727 Z M15.3,5.72727273 C14.9727273,5.72727273 14.6863636,6.01363636 14.6863636,6.34090909 L14.6863636,15.6272727 C14.6863636,16.2409091 14.1954545,16.7727273 13.5409091,16.7727273 L4.41818182,16.7727273 C3.80454545,16.7727273 3.27272727,16.2818182 3.27272727,15.6272727 L3.27272727,6.34090909 C3.27272727,6.01363636 2.98636364,5.72727273 2.65909091,5.72727273 C2.33181818,5.72727273 2.04545455,6.01363636 2.04545455,6.34090909 L2.04545455,15.6272727 C2.04545455,16.9363636 3.10909091,18 4.41818182,18 L13.5818182,18 C14.8909091,18 15.9545455,16.9363636 15.9545455,15.6272727 L15.9545455,6.34090909 C15.9136364,6.01363636 15.6272727,5.72727273 15.3,5.72727273 Z" id="9001"></path>
    <path d="M7.28181818,14.9318182 L7.28181818,6.34090909 C7.28181818,6.01363636 6.99545455,5.72727273 6.66818182,5.72727273 C6.34090909,5.72727273 6.05454545,6.01363636 6.05454545,6.34090909 L6.05454545,14.9318182 C6.05454545,15.2590909 6.34090909,15.5454545 6.66818182,15.5454545 C6.99545455,15.5454545 7.28181818,15.2590909 7.28181818,14.9318182 Z M11.9454545,14.9318182 L11.9454545,6.34090909 C11.9454545,6.01363636 11.6590909,5.72727273 11.3318182,5.72727273 C11.0045455,5.72727273 10.7181818,6.01363636 10.7181818,6.34090909 L10.7181818,14.9318182 C10.7181818,15.2590909 11.0045455,15.5454545 11.3318182,15.5454545 C11.6590909,15.5454545 11.9454545,15.2590909 11.9454545,14.9318182 Z" id="9002"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-delete",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>