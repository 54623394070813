<template>
  <svg t="1711824335638" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="40386" :width="size" :height="size" :fill="color">
    <path d="M859.115184 62.365396 161.97658 62.365396c-54.775534 0-99.590206 44.814672-99.590206 99.590206l0 697.139627c0 54.781674 44.814672 99.590206 99.590206 99.590206l697.138604 0c54.781674 0 99.590206-44.808532 99.590206-99.590206L958.70539 161.955602C958.70539 107.180068 913.896858 62.365396 859.115184 62.365396L859.115184 62.365396zM410.957211 759.505024l-248.980631-248.980631 69.710688-69.706595 179.269943 179.269943 378.452401-378.453425 69.705572 69.711711L410.957211 759.505024 410.957211 759.505024zM410.957211 759.505024" p-id="40387"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-check",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>