import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import VueAxios from "vue-axios";
import ElementPlus from "element-plus"
import {ElMessage, ElMessageBox} from "element-plus"
import 'element-plus/dist/index.css'
import iconComponents from './configuration/icon'
import i18n from './configuration/i18n'
import vue3GoogleLogin from 'vue3-google-login'
const app = createApp(App)
app.config.globalProperties.$axios = axios
app.config.globalProperties.$message = ElMessage
app.config.globalProperties.$alert = ElMessageBox.alert
app.config.globalProperties.$confirm = ElMessageBox.confirm
iconComponents(app)
app.use(VueAxios, axios).use(store).use(router).use(ElementPlus).use(i18n).use(vue3GoogleLogin, {clientId: '538824414358-pql3qsou5c7skcuhbpeq6el7jnomq0m1.apps.googleusercontent.com'}).mount('#app')