<template>
  <svg viewBox="0 0 28 24" version="1.1" xmlns="http://www.w3.org/2000/svg" :fill="color" :width="size" :height="size-4">
    <path d="M12.0971667,16.0218333 C11.765268,16.0247856 11.4561519,15.8533989 11.2828333,15.5703333 L11.221,15.4431667 L6.34083333,8.995 C6.31145022,8.93749124 6.29549437,8.87406672 6.29416667,8.8095 C6.30178964,8.5590948 6.5103975,8.36196037 6.76083333,8.3685 C6.86583333,8.3685 6.96266667,8.40116667 7.04083333,8.456 L12.3818333,11.6445 C12.7551371,11.8725187 13.2106939,11.922138 13.6243333,11.7798333 L25.4356667,4.66666667 C22.8993333,1.84333333 18.725,0 14,0 C6.26733333,0 0,4.935 0,11.0203333 C0,14.3406667 1.88533333,17.3296667 4.837,19.3491667 C5.07886382,19.5082544 5.22579446,19.77719 5.229,20.0666667 C5.22611609,20.162623 5.209618,20.2576834 5.18,20.349 L4.54883333,22.5703333 C4.5185,22.6753333 4.473,22.7838333 4.473,22.8923333 C4.473,23.1373333 4.683,23.3333333 4.93966667,23.3333333 C5.04,23.3333333 5.12283333,23.2983333 5.208,23.2516667 L8.274,21.581 C8.505,21.455 8.74766667,21.3768333 9.01716667,21.3768333 C9.16066667,21.3768333 9.29716667,21.3978333 9.429,21.434 C10.8581667,21.8225 12.4016667,22.0395 13.9988333,22.0395 C21.7315,22.0383333 28,17.1033333 28,11.0203333 C28,9.17583333 27.4201667,7.43983333 26.404,5.91383333 L12.6186667,15.8713333 L12.5171667,15.9261667 C12.3862189,15.9894533 12.2426053,16.0221653 12.0971667,16.0218333 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-alipay",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>