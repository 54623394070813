<template>
  <svg t="1711606350532" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9966" :width="size" :height="size" :fill="color">
    <path d="M363.648 600.704L667.904 339.2 265.6 523.648 44.544 349.568a33.28 33.28 0 0 1-11.712-33.28 32.32 32.32 0 0 1 24.576-24.896l896.32-190.144a31.36 31.36 0 0 1 29.312 9.28 33.472 33.472 0 0 1 8.064 30.464l-175.616 757.248a32.512 32.512 0 0 1-20.288 23.296 31.168 31.168 0 0 1-30.08-4.864L363.648 600.704z m-83.52 261.504l43.84-211.2 171.84 136.576-167.488 109.184A31.104 31.104 0 0 1 293.12 896a33.28 33.28 0 0 1-12.992-33.792z" p-id="9967"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-send-h",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>