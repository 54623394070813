export default {
  title: "Snowblue AI",
  login: {
    t1: "邮箱",
    t2: "请输入你的邮箱",
    t3: "密码",
    t4: "请输入你的密码",
    t5: "Google账号登录",
    t6: "登录",
    t7: "没有账号吗？",
    t8: "注册",
    t9: "创建账号",
    t10: "我统一SnowBlueAI的服务条款和隐私政策。",
    t11: "已经有账号了吗？",
    t13: "正在登录中...",
    t14: "正在注册中...",
    t15: "载入中...",
    t16: "删除中...",
  },
  home: {
    t1: "开始",
    t2: "演示 & 代码",
    t3: "演示",
    t4: "代码",
    t5: "升级订阅",
    t6: "升级",
    t7: "扩展功能",
    t8: "订单记录",
    t9: "支持中心",
    t10: "可用积分",
    t11: "个人中心",
    t12: "退出登录"
  },
  header: {
    cs: "机器人设置",
    demo: "您可以在这里测试您创建的机器人助理。",
    ons: "当你选择的套餐不足以满足需求时，你可以购买更多服务。",
    id: "邀请好友的详细信息",
  },
  started: {
    t1: "AI助手配置提交",
    t2: "设置助手名称",
    t3: "选择一个能代表你的助手的名字。",
    t4: "选择助手模型",
    t5: "选择一个定义你的助手的模型。",
    t6: "设置助手指令",
    t7: "设定一套定义你的助手的指令。",
    t8: "上传知识文件",
    t9: "通过上传文件建立你的聊天机器人的知识库。这些文件训练你的聊天机器人准确回答问题。",
    t10: "选择文件",
    t12: "最多10个文件，每个文件不超过10MB。",
    t13: "确认信息",
    t14: "助手名称：",
    t15: "模型：",
    t16: "指令：",
    t17: "恭喜，你已成功创建一个AI助手。现在开始与它聊天吧。",
    b1: "下一步",
    b2: "返回",
    b3: "检查状态",
    b4: "升级订阅",
    b5: "确认",
    b6: "访问升级订阅",
    b7: "访问演示",
  },
  demo: {
    t1: "发送消息",
    t2: "选择机器人",
    t3: "选择语言模型",
    t4: "新的会话",
    t5: "会话",
  },
  codes: {
    t1: "AI助手聊天机器人气泡",
    t2: "要将聊天机器人作为一个便捷的弹出气泡显示，请将此代码插入到您网站的 \x3Chead\x3E 部分：",
    t3: "AI助手聊天机器人 Iframe（嵌入式）",
    t4: "要将聊天机器人作为一个便捷的弹出气泡显示，请将此代码插入到您网站的 \x3Chead\x3E 部分：",
  },
  settings: {
    t1: "删除机器人",
    t2: "添加文件",
    t3: "文件名",
    t4: "上传时间",
  },
  plan: {
    t1: "请选择一项支付方式",
    t2: "支付宝",
    t3: "微信支付",
    t4: "信用卡",
    p0: {
      a: "免费计划",
      b: "",
      c: "6,000 字",
      d: "免费",
      e: "10 条消息",
      f: "10 条消息",
      g: "基础功能",
      h: "邮件支持",
      i: "网站 和 WhatsApp",
      j: "1 个AI助手",
    },
    p1: {
      a: "GPT-3.5 Turbo",
      b: "( 月付套餐 )",
      c: "200,000 字",
      d: "$99",
      e: "500 条消息 / 天",
      f: "15,000 条消息 / 月",
      g: "基础功能",
      h: "邮件支持",
      i: "网站 和 WhatsApp",
      j: "5 个AI助手",
    },
    p2: {
      a: "GPT-3.5 Turbo",
      b: "( 年付套餐 )",
      c: "240 万字 / 年",
      d: "$950",
      e: "500 条消息 / 天",
      f: "15,000 条消息 / 月",
      g: "基础功能",
      h: "邮件优先支持",
      i: "网站 和 WhatsApp",
      j: "5 个AI助手",
      k: "$1188",
    },
    p3: {
      a: "GPT-4o",
      b: "( 月付套餐 )",
      c: "100,000 字",
      d: "$199",
      e: "500 条消息 / 天",
      f: "15,000 条消息 / 月",
      g: "高级功能",
      h: "邮件支持和在线支持",
      i: "网站 和 WhatsApp",
      j: "5 个AI助手",
    },
    p4: {
      a: "GPT-4o",
      b: "( 年付套餐 )",
      c: "120 万字 / 年",
      d: "$1910",
      e: "500 条消息 / 天",
      f: "15,000 条消息 / 月",
      g: "高级功能",
      h: "邮件优先支持和在线支持",
      i: "网站 和 WhatsApp",
      j: "5 个AI助手",
      k: "$2388",
    },
    o: "( 8折优惠 )",
    alert1 : "付款成功后，请等待后台处理，通常在3分钟内完成。您可以在订单记录中查看订单进度。如果1小时后仍未成功更新，请联系管理员。",
    alert2 : "如果浏览器没有打开结账页面，请复制以下链接手动打开结账页面。",
  },
  ons: {
    t1: "额外消息额度",
    t2: "额外聊天机器人",
    t3: "聊天记录",
    c1: "每1000条消息10美元",
    c2: "每月额度",
    c3: "每个聊天机器人每月5美元。",
    c4: "从您的AI助手聊天机器人中检索完整的聊天历史。",
    w: "我想要",
    e1: "每月额外1000条消息额度。",
    e2: "每月额外的聊天机器人。",
    b1: "您将被收费",
    b2: "每月10美元",
    b3: "每月5美元",
    b4: "每月49美元",
    u1: "即将推出",
  },
  order: {
    th1: "订阅类型",
    th2: "订单状态",
    th3: "支付方式",
    th4: "开始时间",
    th5: "关闭时间",
    th6: "订单号",
    th7: "刷新",
    status: {
      s1: "处理中",
      s2: "有效的",
      S3: "关闭的"
    },
    b1: "退订",
    b2: "支付",
  },
  support: {
    qa1: {
      q: "GPT-4.0套餐相比GPT-3.5套餐有哪些增强功能？",
      a: "GPT-4.0套餐引入了高级功能，能够实现更复杂的互动和更广泛的集成能力，展示了人工智能技术的最新发展。"
    },
    qa2: {
      q: "注册后是否可以修改我的订阅计划？",
      a: "是的，订阅者可以随时根据他们不断变化的需求调整他们的订阅等级。"
    },
    qa3: {
      q: "超过我分配的字数限制有什么后果？",
      a: "如果您的使用超过了指定的字数限制，超出的字数将按预定的费率收费，并在收取任何额外费用前提前通知。"
    },
    qa4: {
      q: "Snowblue AI助手为新用户提供试用期吗？",
      a: "新用户可以利用7天免费试用期探索AI助手服务的全部功能，然后再决定是否订阅付费服务。"
    },
    qa5: {
      q: "Snowblue AI助手提供什么类型的客户支持？",
      a: "每个订阅计划都包括电子邮件支持，GPT-4.0套餐的订户还可获得额外的聊天支持。年度订户在支持查询中享有优先权。"
    },
    qa6: {
      q: "AI助手如何确保我的数据的安全性和隐私性？",
      a: "AI助手通过使用加密的AWS服务器来存储数据，确保您的文件保持私密且无法被他人访问，从而优先考虑数据安全。"
    },
    qa7: {
      q: "我可以使用哪些格式来为我的聊天机器人提供数据？",
      a: "该服务支持多种数据输入方式，包括多种文件类型和直接文本输入，以创建多功能的聊天机器人体验。"
    },
    qa8: {
      q: "我可以个性化我的AI助手聊天机器人吗？",
      a: "用户可以通过独特的提示、名称、个性特征和具体指令来定制他们的聊天机器人，以调整聊天机器人的回应。"
    },
    qa9: {
      q: "我如何将AI助手集成到我的网站上？",
      a: "将AI助手集成到您的网站很简单，只需要训练您的定制AI模型，并在嵌入iframe和添加聊天气泡之间进行选择。"
    },
    qa10: {
      q: "AI助手支持哪些语言，能否满足国际观众的需求？",
      a: "AI助手支持超过100种语言，可以用各种语言回应查询，使其成为一个与全球观众互动的多功能工具。"
    },
    qa11: {
      q: "有没有限制可以与我的聊天机器人互动的用户数量？",
      a: "聊天机器人对任何拥有链接的人都是可访问的，没有用户数量限制。"
    },
    qa12: {
      q: "是否可以将多个文件整合到一个聊天机器人中？",
      a: "是的，用户可以将多个文件上传到一个聊天机器人中，实现全面的数据整合。"
    },
    qa13: {
      q: "我可以创建多少个AI助手聊天机器人？",
      a: "您可以创建的聊天机器人数量由您的订阅等级决定，每个知识库相当于一个聊天机器人。"
    },
  },
  profile: {
    t1: "需要更多?",
    t2: "立刻升级",
    t3: "邀请好友",
    t4: "邀请者和被邀请者都可以获得5个积分。",
    t5: "详细",
    t6: "修改密码",
    t7: "原密码",
    t8: "新密码",
    t9: "确认新密码",
    t10: "确认修改",
    t11: "输入你的原始密码",
    t12: "输入你的新密码",
    t13: "确认你的新密码",
  },
  invited: {
    t1: "总计",
    t2: "被邀请邮箱",
    t3: "注册时间",
    t4: "获得积分"
  },
  msg: {
    s1: "成功复制邀请链接",
    s2: "切换成功",
    s3: "切换失败，请重试或联系管理员",
    s4: "复制成功",
    s5: "登录失败",
    s6: "注册成功",
    s7: "请检查条款",
    s8: "邮箱和密码不能为空",
    s9: "请先订阅计划",
    s10: "创建订单失败",
    s11: "订阅已过期，请重新订阅该计划",
    s12: "修改成功",
    s13: "错误",
    s14: "修改密码错误，请重试",
    s15: "修改机器人名称成功",
    s16: "修改指令成功",
    s17: "最多选择10个文件",
    s18: "添加成功",
    s19: "添加失败",
    s20: "删除文件失败",
    s21: "删除机器人失败",
    s22: "未发现有效的交易，可能正在进行中。",
    s23: "助理名称不能超过30个字",
    s24: "助理名称不能为空",
    s25: "模型不能为空",
    s26: "指令不能超过32768个字",
    s27: "指令不能为空",
    s28: "已经创建AI助理，不再提供免费创建",
    s29: "请至少上传一个知识文件",
    s30: "创建AI助理失败，请重新尝试创建",
    s31: "当前的订阅不允许创建使用GPT-4.0模型的聊天机器人。",
    s32: "文件大小不能超过10MB",
    s33: "最多上传10个文件",
    s34: "网络异常",
  }
}