<template>
  <svg viewBox="0 0 26 21" version="1.1" xmlns="http://www.w3.org/2000/svg" :fill="color" :width="size" :height="size-5">
    <path d="M26,7.3125 L26,16.9715 C26,18.7664254 24.5449254,20.2215 22.75,20.2215 L3.25,20.2215 C2.38804641,20.2215 1.56139619,19.8790903 0.951902961,19.269597 C0.342409731,18.6601038 1.15463195e-15,17.8334536 1.15463195e-15,16.9715 L1.15463195e-15,7.3125 L26,7.3125 Z M21.606,12.9999992 L15.782,12.9999992 C15.6360446,12.9996878 15.5077827,13.0967149 15.468375,13.23725 L14.73875,15.83725 C14.7112573,15.9352922 14.7313032,16.0405524 14.7929071,16.121627 C14.8545109,16.2027015 14.9505513,16.2502179 15.052375,16.2500008 L20.876375,16.2500008 C21.0223304,16.2503122 21.1505923,16.1532851 21.19,16.01275 L21.919625,13.41275 C21.9471177,13.3147078 21.9270718,13.2094476 21.8654679,13.128373 C21.8038641,13.0472985 21.7078237,12.9997821 21.606,12.9999992 L21.606,12.9999992 Z M22.75,0 C23.6119536,0 24.4386038,0.342409731 25.048097,0.951902961 C25.6575903,1.56139619 26,2.38804641 26,3.25 L26,5.6875 L1.15463195e-15,5.6875 L1.15463195e-15,3.25 C1.15463195e-15,1.45507456 1.45507456,0 3.25,0 L22.75,0 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-alipay",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>