<template>
  <router-view/>
</template>

<!--<script>-->
<!--export default {-->
<!--  setup() {-->
<!--    localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJsaWFuZ3poaW15MjhAMTI2LmNvbSIsInVzZXJfaWQiOiJhMDQ1ZjNjZS0yZDQ3LTQwNjctYTgwMy1iNzZkY2MxMWJmMTYiLCJleHAiOjE3MTQ0MTg0MjcsIm0iOjB9.gWLMcTixKbN_U6rlqN-pF3VQ2u5rwwgDMaMEd4H4fVA")-->
<!--  }-->
<!--}-->
<!--</script>-->

<style lang="scss">
#app {
  font-family:PingFangSC-Regular, Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 16px;
  color: #2c3e50;
}
* {
  margin: 0;
}
.font_medium {
  font-weight: 600;
}
input {
  font-family: PingFangSC-Regular, Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 0;
}
textarea {
  font-family: PingFangSC-Regular, Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 0;
}
:root {
  --thematic-color-light-super: #fff;
  --thematic-color-light: #fafafa;
  --thematic-color-basic: #f4f4f4;
  --thematic-color-dark: #f8f8f8;
  --thematic-color-dark-super: #e6e6e6;
  --thematic-font-basic: #2b2b2b;
  --thematic-font-light: #8b8b8b;
  --thematic-font-basic-2: #13435e;
  --thematic-font-dark-super: #000;
  --thematic-font-active: #2563ea;
  --thematic-font-tip: #687591;
  --thematic-info-light: #a7c0f7;
  --thematic-info-basic: #2d74ff;
  --thematic-info-back: #f1f6ff;
  --thematic-input-border: #dfdfdf;
  --thematic-input-hover: #f2f4ff;
  --thematic-input-active: #6c7ac5;
  --thematic-step-line: #dde2ea;
  --thematic-step-back: #dfeafc;
  --login-root-color: #3e62a4;
  --login-root-color-light: #597bc4;
  --login-root-color-black: #294c80;
  --login-background-color: #c9c9c9;
}
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background: none; /* 轨道的背景颜色 */
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background: #d8d8d8; /* 滑块的背景颜色 */
  border-radius: 3px;
}

/* 滚动条滑块悬浮时的样式 */
::-webkit-scrollbar-thumb:hover {
  background: #888; /* 滑块悬浮时的背景颜色 */
}
.i-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
>>>.el-table {
  font-size: 16px!important;
}
>>>.el-table .el-table__cell {
  padding: 25px 0!important;
}
.h_button {
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h_button.blue_button {
  background-color: var(--thematic-font-active);
  color: var(--thematic-color-light-super);
}
.h_button.blue_button:hover {
  background-color: var(--thematic-info-basic);
}
.h_button.grey_button {
  background-color: var(--thematic-color-basic);
  color: var(--thematic-font-basic);
  //outline: 1px solid var(--login-background-color);
}
.h_button.grey_button:hover {
  background-color: var(--login-background-color);
}
.h_button.is_disabled_button {
  color: var(--login-background-color)!important;
  cursor: not-allowed;
}
.h_button.grey_button.is_disabled_button:hover {
  background-color: var(--thematic-color-basic)!important;
}
.h_button.blue_button.is_disabled_button:hover {
  background-color: var(--thematic-font-active)!important;
}
.main_inner_box {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 20px 50px;
  text-align: left;
}
.main_body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rotating {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
