<template>
  <svg viewBox="0 0 18 19" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" :fill="color">
    <path d="M10.2141448,0.219215431 C10.527294,-0.0791414426 11.0216058,-0.0720798461 11.3261045,0.235100571 L15.6548051,4.60113118 C15.8093143,4.75675874 15.8926014,4.9693445 15.8849298,5.18851149 C15.8772582,5.40767849 15.7793107,5.61392041 15.614298,5.7583636 L5.18411532,14.8939074 C5.03499542,15.0247028 4.8423322,15.0949682 4.64402058,15.0908831 L0.777577602,15.0082804 C0.345448897,14.9992037 0,14.6462474 0,14.2140234 L0,10.2872169 C0,10.0699259 0.0889424163,9.86210563 0.246219683,9.71217485 L10.2141448,0.219215431 Z M11.2681238,12.9892791 L17.2210798,13.1052407 C17.5048405,13.110774 17.7640942,13.2672715 17.9011826,13.5157822 C18.0382709,13.7642928 18.0323669,14.0670618 17.8856946,14.3100391 C17.7390222,14.5530164 17.4738645,14.699288 17.1901038,14.6937546 L11.2371478,14.5777931 C10.9533871,14.5721179 10.6942091,14.4154892 10.5572436,14.1669077 C10.4202781,13.9183261 10.4263335,13.6155571 10.5731287,13.3726507 C10.719924,13.1297443 10.9851574,12.9836039 11.268918,12.9892791 L11.2681238,12.9892791 Z M10.7462969,1.90621725 L1.58851398,10.6271589 L1.58851398,13.4364458 L4.3684134,13.4960151 L13.9320617,5.11978099 L10.7462969,1.90621725 Z M17.1821612,16.960564 C17.6208173,16.9572741 17.9790852,17.3102081 17.982398,17.7488641 C17.9856651,18.1875201 17.6327311,18.5457881 17.1940751,18.549078 L1.59486803,18.6650395 C1.15621202,18.6683294 0.797944059,18.3153955 0.794631324,17.8767395 C0.791364219,17.4380834 1.14429817,17.0798155 1.58295418,17.0765256 L17.1821612,16.960564 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-edit",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>