<template>
  <svg t="1712909425486" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9400" :width="size" :height="size">
    <path d="M666.752 42.752a37.290667 37.290667 0 0 1 22.272 5.589333l4.010667 2.858667 2.389333 2.133333 211.626667 217.002667 2.474666 3.029333c5.12 6.826667 7.594667 14.549333 7.893334 22.272l-0.042667 484.565334c0 108.544-84.010667 197.546667-189.781333 201.045333l-6.4 0.085333H302.848c-105.813333 0-192.597333-86.144-196.053333-194.602666l-0.085334-6.528V243.882667c0-108.544 84.010667-197.546667 189.781334-201.045334l6.357333-0.085333h363.946667z m-37.077333 80.426667H302.805333c-62.848 0-114.602667 50.645333-117.546666 115.029333l-0.128 5.674667v536.32c0 64.426667 49.365333 117.504 112.128 120.533333l5.546666 0.128h418.432c62.848 0 114.602667-50.602667 117.546667-114.986667l0.128-5.674666V337.749333h-117.674667c-48.64 0-88.746667-38.954667-91.392-88.746666l-0.128-5.12-0.042666-120.704z m78.506666 56.917333v63.786667c0 5.589333 3.370667 10.624 8.533334 12.586666l2.133333 0.597334 2.389333 0.213333h62.208l-75.306666-77.226667z m-143.872 224.682667a39.68 39.68 0 0 1 39.168 38.272 39.893333 39.893333 0 0 1-35.413333 41.984l-3.754667 0.170666H328.96a39.68 39.68 0 0 1-39.168-38.272 39.893333 39.893333 0 0 1 35.413333-41.984l3.754667-0.170666h235.349333z m0 160.896a39.68 39.68 0 0 1 39.168 38.272 39.893333 39.893333 0 0 1-35.413333 41.984l-3.754667 0.213333H328.96a39.68 39.68 0 0 1-39.168-38.314667 39.936 39.936 0 0 1 35.413333-41.984l3.754667-0.170666h235.349333z" p-id="9401"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-file-two",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>