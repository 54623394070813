<template>
  <svg t="1714026836764" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4443" :fill="color" :width="size" :height="size">
    <path d="M512 170.666667C323.477333 170.666667 170.666667 323.477333 170.666667 512s152.810667 341.333333 341.333333 341.333333 341.333333-152.810667 341.333333-341.333333h85.333334c0 235.648-191.018667 426.666667-426.666667 426.666667S85.333333 747.648 85.333333 512 276.352 85.333333 512 85.333333v85.333334z" p-id="4444"></path>
  </svg>
</template>

<script>
export default {
  name: 'icon-loading',
  props: {
    size: {
      type: String,
      default: 14
    },
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>

<style scoped>

</style>