<template>
  <svg t="1715911007889" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4259" :width="size" :height="size" :fill="color">
    <path d="M512 936a424.1 424.1 0 0 1-165.05-814.66 424.1 424.1 0 0 1 330.1 781.33A421.38 421.38 0 0 1 512 936z m0-768c-189.68 0-344 154.32-344 344s154.32 344 344 344 344-154.32 344-344-154.32-344-344-344z" p-id="4260"></path>
    <path d="M349.75 349.75m57.15 0l210.2 0q57.15 0 57.15 57.15l0 210.2q0 57.15-57.15 57.15l-210.2 0q-57.15 0-57.15-57.15l0-210.2q0-57.15 57.15-57.15Z" p-id="4261"></path>
  </svg>
</template>

<script>
export default {
  name: 'icon-stop',
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>