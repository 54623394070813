<template>
  <svg t="1712997302796" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4247" :file="color" :width="size" :height="size">
    <path d="M932.394667 442.197333l-372.096-358.101333a71.893333 71.893333 0 0 0-96.512 0l-372.181334 358.101333a35.925333 35.925333 0 1 0 48.213334 53.418667L512 137.514667l372.181333 358.101333a35.925333 35.925333 0 1 0 48.213334-53.418667z m-99.413334 132.821334a35.968 35.968 0 0 0-35.968 35.968v277.034666h-143.018666v-240c0-39.808-32.213333-72.021333-71.978667-72.021333h-140.032c-39.765333 0-71.978667 32.213333-71.978667 72.021333v240H226.986667V610.986667a35.968 35.968 0 1 0-72.021334 0v277.034666c0 39.765333 32.213333 71.978667 72.021334 71.978667h570.026666c39.765333 0 71.978667-32.213333 71.978667-71.978667V610.986667a35.968 35.968 0 0 0-36.010667-35.968z m-390.997333 72.96h140.032v240.042666h-140.032v-240z" p-id="4248"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-home",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>