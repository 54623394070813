<template>
  <svg viewBox="0 0 24 23" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size-1">
    <path d="M23.0259556,5.00011697 L17.0740916,0.398889981 C15.8282209,-0.563828283 14.0188768,0.325270349 14.0188768,1.89959786 L14.0188768,3.1737838 C8.67012742,3.21059361 4.33789523,7.69855964 4.33789523,13.2540103 C4.33789523,14.4630712 4.53893346,15.5390504 4.87588485,16.6150297 C6.21802737,12.7160207 9.78291647,9.89299097 14.0160453,9.89299097 L14.0160453,11.1020518 C14.0160453,12.6763794 15.8253893,13.565478 17.07126,12.6027597 L23.0259556,8.00153274 C24.0084946,7.24268422 24.0084946,5.75896549 23.0259556,5.00011697 Z" id="9001" fill="#2867CE"></path>
    <path d="M19.2288815,22.2922359 L4.77111845,22.2922359 C2.13496933,22.2922359 0,20.154435 0,17.5211174 L0,6.76698814 C0,4.13083901 2.13780085,1.99586969 4.77111845,1.99586969 L6.17555451,1.99586969 C6.92873997,1.99586969 7.5375177,2.60464741 7.5375177,3.35783288 C7.5375177,4.11101834 6.92873997,4.71979607 6.17555451,4.71979607 L4.77111845,4.71979607 C3.63850873,4.71979607 2.72109486,5.63720994 2.72109486,6.76981966 L2.72109486,17.523949 C2.72109486,18.6565587 3.63850873,19.5739726 4.77111845,19.5739726 L19.22605,19.5739726 C20.3586597,19.5739726 21.2760736,18.6565587 21.2760736,17.523949 L21.2760736,13.7863369 C21.2760736,13.0331514 21.8848513,12.4243737 22.6380368,12.4243737 C23.3912223,12.4243737 24,13.0331514 24,13.7863369 L24,17.523949 C24,20.1572666 21.8621992,22.2922359 19.2288815,22.2922359 L19.2288815,22.2922359 Z" id="9002" fill="#BDD2EF"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-invite",
  props: {
    size: {
      type: String,
      default: "24"
    },
  }
}
</script>

<style scoped>

</style>