<template>
  <svg t="1711732698473" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="22730" :width="size" :height="size" :fill="color">
    <path d="M486.863 323.033L406.44 131.721 288.763 302.639 97.45 383.079l170.918 117.676 80.423 191.313 117.693-170.935L657.78 440.71z m438.614 27.618l-29.997-86.473-56.626 71.91-86.456 29.998 71.91 56.609 29.997 86.473 56.61-71.91 86.455-29.997zM712.362 637.335l-26.629 40.522-44.292 19.741 40.522 26.629 19.74 44.292 26.647-40.521 44.275-19.742-40.522-26.629z" p-id="22731"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-star",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>