<template>
  <svg viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="size" :height="size" :fill="color">
    <path d="M18.307967,4.12407945 C18.0207543,2.58736889 16.6706092,1.41887971 15.0504352,1.41887971 L11.2430261,1.41887971 C10.5532247,0.554786878 9.48292792,-4.46527191e-16 8.28252622,-4.46527191e-16 L3.77058692,-4.46527191e-16 C1.69136353,-4.46527191e-16 -6.69790787e-16,1.66436063 -6.69790787e-16,3.70921669 L-6.69790787e-16,14.5987503 C-6.69790787e-16,16.6436063 1.69136353,18.307967 3.77058692,18.307967 L18.2294131,18.307967 C20.3086365,18.307967 22,16.6436063 22,14.5987503 L22,7.82838652 C22,5.81053336 20.3503682,4.1658112 18.307967,4.12407945 Z M15.0504352,2.74447668 C15.9341665,2.74447668 16.6828833,3.32381165 16.9406383,4.11916983 L12.0531131,4.11916983 L12.0531131,3.70676188 C12.0531131,3.37290783 12.004017,3.05132783 11.9205534,2.74447668 L15.0504352,2.74447668 L15.0504352,2.74447668 Z M1.32559696,3.70676188 C1.32559696,2.34434278 2.42289667,1.23476902 3.77058692,1.23476902 L8.28498103,1.23476902 C9.63267128,1.23476902 10.729971,2.34434278 10.729971,3.70676188 L10.729971,4.11916983 L1.32805177,4.11916983 L1.32805177,3.70676188 L1.32559696,3.70676188 Z M20.6719482,14.5962955 C20.6719482,15.9587146 19.5746485,17.0682883 18.2269583,17.0682883 L3.76813211,17.0682883 C2.42044187,17.0682883 1.32314216,15.9587146 1.32314216,14.5962955 L1.32314216,5.35639366 L18.2245035,5.35639366 C19.5721937,5.35639366 20.6694934,6.46596742 20.6694934,7.82838652 L20.6694934,14.5962955 L20.6719482,14.5962955 Z" id="9000"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-folder",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>