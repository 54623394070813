<template>
  <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" :fill="color">
    <path d="M14.3258929,5.97544643 L18.7678571,0.828125 L17.8348214,0 L13.15625,5.41741071 C12.1138393,4.98214286 11.0401786,4.70089286 10,4.70089286 C5.34151786,4.70089286 0,10.3325893 0,10.3325893 C0,10.3325893 2.36607143,12.8258929 5.32142857,14.4955357 L1.28571429,19.1696429 L2.22098214,20 L6.46651786,15.0803571 C7.625,15.609375 8.83258929,15.9620536 10,15.9620536 C14.6584821,15.9620536 20,10.3303571 20,10.3303571 C20,10.3303571 17.4441964,7.63839286 14.3258929,5.97544643 Z M6.25,10.3325893 C6.25,8.25892857 7.93080357,6.578125 10,6.578125 C10.640625,6.578125 11.2433036,6.73883929 11.7723214,7.02232143 L10.9241071,8.00446429 C10.6383929,7.890625 10.328125,7.828125 10.0022321,7.828125 C8.62276786,7.828125 7.50223214,8.95089286 7.50223214,10.3303571 C7.50223214,10.7857143 7.625,11.2120536 7.83705357,11.5803571 L6.98883929,12.5625 C6.52455357,11.9419643 6.25,11.1696429 6.25,10.3325893 Z M10,14.0870536 C9.21651786,14.0870536 8.48883929,13.8459821 7.88839286,13.4352679 L8.71428571,12.4799107 C9.08928571,12.7075893 9.53125,12.8370536 10,12.8370536 C11.3794643,12.8370536 12.5,11.7142857 12.5,10.3348214 C12.5,9.734375 12.2879464,9.18303571 11.9352679,8.75 L12.7611607,7.79464286 C13.375,8.45982143 13.75,9.35267857 13.75,10.3325893 C13.75,12.4040179 12.0714286,14.0870536 10,14.0870536 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-preview-close",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>