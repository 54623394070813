import {
    ApplicationMenu,
    Info,
    FileSuccessOne,
    Plus,
    Minus,
    CloseOne,
    Check,
    Close,
    Left,
    Copy, SettingTwo, Share, Refresh, Voice
} from '@icon-park/vue-next'
import iconSendH from "@/components/icon/icon-send-h"
import iconSnowLogo from "@/components/icon/icon-snow-logo"
import iconUser from "@/components/icon/icon-user"
import iconFile from "@/components/icon/icon-file"
import iconStar from "@/components/icon/icon-star"
import iconFolder from "@/components/icon/icon-folder"
import iconDelete from "@/components/icon/icon-delete"
import iconSuccess from "@/components/icon/icon-success"
import iconGpt from "@/components/icon/icon-gpt"
import iconGoogle from "@/components/icon/icon-google"
import iconCheck from "@/components/icon/icon-check"
import iconEdit from "@/components/icon/icon-edit"
import iconPreviewOpen from "@/components/icon/icon-preview-open"
import iconPreviewClose from "@/components/icon/icon-preview-close"
import iconCelebrate from "@/components/icon/icon-celebrate"
import iconInvite from "@/components/icon/icon-invite"
import iconAlipay from "@/components/icon/icon-alipay"
import iconWechatpay from "@/components/icon/icon-wechatpay"
import iconCreditcards from "@/components/icon/icon-creditcards"
import iconFileTwo from "@/components/icon/icon-file-two"
import iconHome from "@/components/icon/icon-home"
import iconGenerate from "@/components/icon/icon-generate"
import iconLoading from "@/components/icon/icon-loading"
import iconTranslate from "@/components/icon/icon-translate"
import iconStop from "@/components/icon/icon-stop"
import iconRecording from "@/components/icon/icon-recording"

export default (app) => {
    app.component('icon-left', Left)
    app.component('icon-send', iconSendH)
    app.component('icon-menu', ApplicationMenu)
    app.component('icon-logo', iconSnowLogo)
    app.component('icon-user', iconUser)
    app.component('icon-info', Info)
    app.component('icon-delete', iconDelete)
    app.component('icon-file', iconFile)
    app.component('icon-file-success', FileSuccessOne)
    app.component('icon-google', iconGoogle)
    app.component('icon-star', iconStar)
    app.component('icon-folder', iconFolder)
    app.component('icon-success-round', iconSuccess)
    app.component('icon-plus', Plus)
    app.component('icon-minus', Minus)
    app.component('icon-gpt', iconGpt)
    app.component('icon-check', iconCheck)
    app.component('icon-close', CloseOne)
    app.component('icon-edit', iconEdit)
    app.component('icon-check-one', Check)
    app.component('icon-close-one', Close)
    app.component('icon-preview-open', iconPreviewOpen)
    app.component('icon-preview-close', iconPreviewClose)
    app.component('icon-celebrate', iconCelebrate)
    app.component('icon-invite', iconInvite)
    app.component('icon-copy', Copy)
    app.component('icon-setting', SettingTwo)
    app.component('icon-share', Share)
    app.component('icon-refresh', Refresh)
    app.component('icon-ali-pay', iconAlipay)
    app.component('icon-wechat-pay', iconWechatpay)
    app.component('icon-credit-cards', iconCreditcards)
    app.component('icon-file-two', iconFileTwo)
    app.component('icon-home', iconHome)
    app.component('icon-generate', iconGenerate)
    app.component('icon-loading', iconLoading)
    app.component('icon-translate', iconTranslate)
    app.component('icon-voice', Voice)
    app.component('icon-stop', iconStop)
    app.component('icon-recording', iconRecording)
}