import {createI18n} from "vue-i18n";
import zh from "./zh"
import en from "./en";

const language = 'en'
const i18n = createI18n({
    legacy: false,
    locale: localStorage.getItem('lang') || language,
    fallbackLocale: 'en',
    messages: {
        en,zh,
    }
})

export default i18n