<template>
  <svg viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" :fill="color" :width="size" :height="size">
    <path d="M25.9997999,17.7985731 L25.9997999,4.99821162 C25.998365,2.23786259 23.7606377,0.000717160003 21.0002884,0 L4.99821162,0 C2.23836995,0.00143442944 0.00143442944,2.23836995 0,4.99821162 L0,21.0002884 C0.000717571536,23.7604271 2.23807285,25.9977824 4.99821162,25.9985 L21.0002884,25.9985 C23.4199926,25.9967701 25.4919146,24.2641486 25.9218044,21.8829374 C24.5958809,21.3083706 18.8502124,18.8281137 15.8564852,17.3981962 C13.5790165,20.157937 11.1923543,21.8140414 7.5967617,21.8140414 C4.00116915,21.8140414 1.60020768,19.5989692 1.88879103,16.8873257 C2.07858008,15.1090283 3.29920965,12.2010961 8.59900388,12.6989673 C11.3938426,12.9615522 12.6716689,13.4828221 14.9504374,14.2354787 C15.5393034,13.1539411 16.0293752,11.9645097 16.4011537,10.6996827 L6.29813662,10.6996827 L6.29813662,9.69874043 L11.2976482,9.69874043 L11.2976482,7.89964422 L5.1997,7.89964422 L5.1997,6.79860775 L11.2963482,6.79860775 L11.2963482,4.20525737 C11.2963482,4.20525737 11.3509451,3.79968077 11.7994192,3.79968077 L14.299175,3.79968077 L14.299175,6.79860775 L20.7988,6.79860775 L20.7988,7.90094415 L14.299175,7.90094415 L14.299175,9.6974405 L19.6015691,9.6974405 C19.144223,11.5907408 18.4189132,13.4090491 17.4475933,15.0973289 C18.9880045,15.6562967 25.9985,17.7985731 25.9985,17.7985731 L25.9997999,17.7985731 Z M7.19898465,20.0981404 C3.39930387,20.0981404 2.79873852,17.6997788 3.0002269,16.6975366 C3.19911542,15.6991942 4.3001519,14.3966694 6.41253002,14.3966694 C8.83949,14.3966694 11.0142645,15.0180335 13.6245139,16.2893602 C11.7916197,18.6760225 9.53884965,20.0981404 7.19898465,20.0981404 L7.19898465,20.0981404 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-alipay",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>