<template>
  <svg viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" :fill="color">
    <path d="M9.99956658,-8.10990405e-16 C3.46476021,-8.10990405e-16 0,6.19709924 0,6.19709924 C0,6.19709924 3.74105774,11.7053252 9.99956658,11.7053252 C16.2585317,11.7053252 20,6.19709924 20,6.19709924 C20,6.19709924 16.9262811,-8.10990405e-16 9.99956658,-8.10990405e-16 Z M10.005703,9.37867272 C8.01310771,9.37867272 6.39770695,7.80234871 6.39770695,5.85816028 C6.39770695,3.91399464 8.01310771,2.33764783 10.005703,2.33764783 C11.9982982,2.33764783 13.613699,3.91399464 13.613699,5.85816028 C13.613699,7.80234871 11.9982754,9.37867272 10.005703,9.37867272 Z M10,4.42260841 C9.12760513,4.42260841 8.42041392,5.12409666 8.42041392,5.98916894 C8.42041392,6.85424121 9.12760513,7.55572946 10,7.55572946 C10.8723949,7.55572946 11.5795861,6.85424121 11.5795861,5.98916894 C11.5795861,5.12409666 10.8723949,4.42260841 10,4.42260841 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-preview-open",
  props: {
    size: {
      type: String,
      default: "24"
    },
    color: {
      type: String,
      default: "#333"
    }
  }
}
</script>

<style scoped>

</style>