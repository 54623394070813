import { createStore } from 'vuex'
const ax = require("axios")

export default createStore({
  state: {
    token: '',
    user: {
      user_id: "",
      email: "",
      password: "",
      register_time: "",
      update_time: "",
      state: 0,
      points: 0,
      is_admin: 0,
      name: "",
      picture_url: "",
      locale: "",
      family_name: "",
      given_name: "",
      user_type: "",
      assistant_id: "",
    },
    model_type: 0,
    valid_until: "",
    available_points: 0,
    plan_number: 0,
    change_assistant_id: '',
    change_assistant_name: '',
  },
  getters: {
  },
  mutations: {
    setToken(state, nt) {
      state.token = nt
    },
    delToken(state) {
      state.token = ''
    },
    setUser(state, user) {
      state.user = user
    },
    setUid(state, uid) {
      state.user.user_id = uid
    },
    setEmail(state, email){
      state.user.email = email
    },
    setName(state, name) {
      state.user.name = name
    },
    setPlanNumber(state, {id, productList}) {
      switch (id) {
        case productList.product1 : state.plan_number = 1; break;
        case productList.product2 : state.plan_number = 2; break;
        case productList.product3 : state.plan_number = 3; break;
        case productList.product4 : state.plan_number = 4; break;
        default: state.plan_number = 0;
      }
    },
    clearUser(state) {
      state.user.user_id = ""
      state.user.email = ""
      state.user.password = ""
      state.user.register_time = ""
      state.user.update_time = ""
      state.user.state = 0
      state.user.points = 0
      state.user.is_admin = 0
      state.user.name = ""
      state.user.picture_url = ""
      state.user.locale = ""
      state.user.family_name = ""
      state.user.given_name = ""
      state.user.user_type = ""
      state.user.assistant_id = ""
    },
    clearOther (state) {
      state.token = ''
      state.model_type = ""
      state.valid_until = ""
      state.available_points = ""
      state.plan_number = ""
      state.change_assistant_id = ""
      state.change_assistant_name = ""
    },
    setModelType(state, model) {
      state.model_type = model
    },
    setValidUntil(state, un) {
      state.valid_until = un
    },
    setPoints(state, points) {
      state.available_points = points
    },
    pointsDown(state, index) {
      state.available_points -= index
    },
    setChangeAssistantId(state,id) {
      state.change_assistant_id = id
    },
    setChangeAssistantName(state,name) {
      state.change_assistant_name = name
    },
  },
  actions: {
    async setPlanNumberAsync({commit}, id) {
      try {
        const response = await ax.get('/product.json');
        commit('setPlanNumber', {id: id, productList: response.data});
      }catch (err) {
        console.error(err)
      }
    }
  },
  modules: {
  }
})
