<template>
  <svg viewBox="0 0 14 15" version="1.1" xmlns="http://www.w3.org/2000/svg" :width="size" :height="size-1" :fill="color">
    <path d="M13.4126094,2.2851694 C13.7444931,2.60187037 13.9527318,3.02642124 14,3.48272381 L14,12.2268342 C14,13.2160498 13.1987728,14.0183321 12.209558,14.019632 L1.79044201,14.019632 C0.801227233,14.0183322 1.84833405e-16,13.2160498 1.84833405e-16,12.2268342 L1.84833405e-16,1.79279785 C1.84833405e-16,0.803582224 0.801227233,0.00129988972 1.79044201,-1.99040545e-16 L5.26845412,-1.99040545e-16 C5.77657418,0.031401374 6.24927717,0.270877707 6.57516267,0.661992376 L7.14763293,1.32319946 C7.22379701,1.45708869 7.32607328,1.57431919 7.44839579,1.66793808 C7.58004939,1.75713057 7.73719426,1.80096572 7.89600629,1.79279785 L12.123177,1.79279785 C12.6005238,1.78379359 13.062754,1.96029684 13.4126094,2.2851694 Z M10.2714831,8.29571461 C10.3727221,8.07948226 10.3353464,7.82372089 10.176464,7.64550146 L7.44996635,4.69441329 C7.39554162,4.63918281 7.33239899,4.59328502 7.26306934,4.55855958 C7.17984468,4.52490421 7.08578262,4.53127148 7.00785282,4.57583576 C6.91823721,4.58393747 6.83130531,4.61068575 6.7526363,4.65436392 C6.68455078,4.6945385 6.62176341,4.74307999 6.56573929,4.79885573 L3.83924164,7.64785731 C3.74774141,7.72963952 3.68750389,7.84067643 3.66883554,7.96196994 C3.65867136,8.0772429 3.68241795,8.19297338 3.73715504,8.29492933 C3.78582662,8.39985267 3.86670639,8.48650956 3.96802783,8.54229302 C4.06461793,8.59406565 4.17255031,8.62104874 4.28214046,8.62082118 L5.6956473,8.62082118 L5.6956473,10.5840251 C5.7076975,10.7558346 5.7836144,10.9169129 5.90845861,11.035562 C6.02632612,11.1611534 6.18736951,11.2374666 6.35921023,11.2491586 L7.65492485,11.2491586 C7.83795997,11.274933 8.0224644,11.2131659 8.15308946,11.0823864 C8.28371452,10.9516069 8.34526368,10.7670297 8.31927305,10.5840251 L8.31927305,8.62082118 L9.62990801,8.62082118 C9.89694003,8.69327161 10.1757194,8.55320528 10.27698,8.29571461 L10.2714831,8.29571461 Z"></path>
  </svg>
</template>

<script>
export default {
  name: "icon-generate",
  props: {
    size: {
      type: String,
      default: 14
    },
    color: {
      type: String,
      default: '#333'
    }
  }
}
</script>

<style scoped>

</style>